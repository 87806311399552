*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}
body {
  width:100%;
  font-family:Cambria;
  
}
