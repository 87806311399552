.container{
  margin:1rem;
}
.button-list{
  display:flex;
  width:270px;
  margin-left: 40%;
  margin-top: 3rem;
  border:2px solid black;
  background-color: black;
}

.heading{
  font-size: 50px;
  text-align: center;
  
}

.clickButton{
  display: flex;
 align-items: center;
  border: none;
  
}
.dropdown{
 display: flex;
  
}

.clock-box {
  display: flex;
}


